import React, { Component } from "react";
import contactsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import MailOutline from "@material-ui/icons/MailOutline";
import { Link } from "gatsby";
import Input from "@material-ui/core/Input";
// core components
import { Helmet } from "react-helmet";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Captcha from "ps-components/Captcha/Captcha.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import { graphql } from "gatsby";
import CustomSnackbar from "components/Snackbar/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import { lmsRequestCall } from "util/lmsRequest";
import CallComponent from "components/CallComponent";
import {  browserWindow } from "util/localStorageHelper";
var apiBaseUrl = process.env.API_URL;

const menuItems = [
  {
    title: `Regarding New Policy`,
    key: `newPolicy`
  },
  {
    title: `Regarding Existing Policy`,
    key: `existingPolicy`
  },
  {
    title: `Feedback`,
    key: `feedback`
  },
  {
    title: `Other Enquiries`,
    key: `other`
  },
];

class ContactUs extends Component {
  constructor(props) {
    super(props);
    // this.ipdata = getIp()
    this.state = {
      name: [``, true],
      mobile: [``, true],
      email: [``, true],
      message: [``, true],
      query: ``,
      refreshCaptcha: false,
      submitStatus: ``,
      submitButton: false,
      captchaText: ``,
      inputCaptchaText: ``,
      ipdata: ``,
      urlInfo: ``
    };
  }

  setCaptchaText = (captchaText, inputCaptchaText) => {
    this.setState({
      captchaText: captchaText,
      inputCaptchaText: inputCaptchaText
    });
  };
  handleSubmit = async e => {
    e.preventDefault();
    let url = `${apiBaseUrl}form/contact`;
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    let name = this.state.name[0];
    let mobile = this.state.mobile[0];
    let email = this.state.email[0];
    let message = this.state.message[0];
    let query = this.state.query;
    let urlInfo = this.state.urlInfo

    this.setState({
      submitButton: true
    });

    let data = {
      name: name,
      mobile: mobile,
      email: email,
      message: message,
      query: query,
      userip: this.state.ipdata,
      urlInfo: this.state.urlInfo
    };
    let params = {
      method: `POST`,
      mode: `cors`,
      cache: `no-cache`,
      credentials: `same-origin`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    };

    let lmsRequestBody = {
      firstName: name,
      mobileNumber: mobile,
      email: email,
      remarks: menuItems.filter(menu => query === menu.key)[0].title,
      source: `Contact Us`,
      urlInfo: urlInfo
    };
    if (
      name.length !== 0 &&
      this.state.name[1] === true &&
      (mobile.length !== 0 &&
        mobile.length === 10 &&
        this.state.mobile[1] === true) &&
      (email.length !== 0 && this.state.email[1] === true) &&
      query.length !== 0 &&
      this.state.email[0].search(emailRegex) === 0 &&
      this.state.captchaText.split(` `).join(``) ===
        this.state.inputCaptchaText &&
      this.state.captchaText.split().join(``) != `` &&
      this.state.inputCaptchaText != ``
    ){
      if(query === `newPolicy`)
      lmsRequestCall(lmsRequestBody);
    }

    if (
      name.length !== 0 &&
      this.state.name[1] === true &&
      (mobile.length !== 0 &&
        mobile.length === 10 &&
        this.state.mobile[1] === true) &&
      (email.length !== 0 && this.state.email[1] === true) &&
      query.length !== 0 &&
      (message.length !== 0 && this.state.message[1] === true) &&
      this.state.email[0].search(emailRegex) === 0 &&
      this.state.captchaText.split(` `).join(``) ===
        this.state.inputCaptchaText &&
      this.state.captchaText.split().join(``) != `` &&
      this.state.inputCaptchaText != ``
    ) {
      let res = await fetch(url, params);
      if (res.status !== 200) {
        console.log(`error`);
        this.setState({
          submitStatus: `errored`
        });
        // ErrorStatus.responseStatus = true;
      } else {
        this.setState({
          name: [``, true],
          mobile: [``, true],
          email: [``, true],
          message: [``, true],
          query: ``,
          file: [``, true],
          cover: [``, true],
          refreshCaptcha: true,
          submitStatus: `success`,
          submitButton: false,
          captchaText: ``,
          inputCaptchaText: ``
        });
      }
    }
    // }
  };
  handleChange = e => {
    console.log(`e`, e.target.id);
    if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else if (e.target.id === `name`) {
      if ((`nice`, e.target.value.search(`^[a-zA-Z. ']*$`) === 0) && (e.target.value[0] != ' ')) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else if (e.target.id === `message`) {
      if (e.target.value.length <= 300) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else {
      this.setState({ [e.target.id]: [e.target.value, true] });
    }
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateRefreshCaptcha = () => {
    this.setState({
      refreshCaptcha: false
    });
  };
  setSubmitStatus = () => {
    this.setState({
      submitStatus: ``
    });
  };
  componentDidMount = async () => {
    if (this.state.ipdata === ``) {
      let response = await fetch(`https://api.ipify.org/`);
      let NetworkIp = await response.text();
      this.setState({
        ipdata: NetworkIp
      });
    }
    if( this.state.urlInfo === ``) {
      this.setState({
        urlInfo: browserWindow.location.href
      })
    }
  };
  render() {
    var phoneno = new RegExp(
      /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
    );
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    const { classes, data } = this.props;

    var Images = data.allStrapiImages.edges;
    var bwImage = Images[0];
    var clrImage = Images[1];
    var dataout = ``;
    if (this.props.location.search != ``) {
      var datacontact = this.props.location.search.split(`?`);
      var datafinal = datacontact[1];
      var datafeedback = datafinal.split(`=`);
      dataout = datafeedback[1];
    }
    return (
      <Layout
        image1={
          bwImage &&
          bwImage.node &&
          bwImage.node.content &&
          bwImage.node.content.childImageSharp.fluid
        }
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
          <title>Contact Us | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <meta name="title" content="Contact Us | StarHealth.in" />
            <meta name="twitter:title" content="Contact Us | StarHealth.in" />
            <link rel="canonical" href="https://www.starhealth.in/contact-us" />
            <meta
              name="description"
              content="Contact us to get a clarity on different type of Health Insurance Plans that we have and choose the best Health Insurance Plan that accomplish your need."
            />
            <meta
              name="keywords"
              content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India."
            />
            <meta property="og:title" content="Contact Us | StarHealth.in" />
            <meta property="og:description" content="Contact us to get a clarity on different type of Health Insurance Plans that we have and choose the best Health Insurance Plan that accomplish your need." />
            <meta property="og:url" content="https://www.starhealth.in/contact-us"/>
            <meta name="Contact Us | StarHealth.in" />
            <meta name="twitter:description" content="Contact us to get a clarity on different type of Health Insurance Plans that we have and choose the best Health Insurance Plan that accomplish your need." />
            <meta property="twitter:url" content="https://www.starhealth.in/contact-us" />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100 }}
        >
          <div className={classes.section}>
            <div className={`${classes.container} `}>
              {this.state.submitStatus === `success` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`success`}
                  message={`Successfully submitted! We will get in touch with you soon `}
                  flag={this.setSubmitStatus}
                />
              )}
              {this.state.submitStatus === `errored` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`error`}
                  message={`We are currently facing issues , please try again later`}
                  flag={this.setSubmitStatus}
                />
              )}
              <GridContainer
                className={`${classes.container} ${classes.paddingcontactfix}`}
              >
                <GridItem xs={12} sm={6} md={6}>
                  {/* <h5 className={classes.title}>Mail In</h5>
                  <h5 className={classes.description}>
                    The Manager - Customer Care, <br />
                    Star Health and Allied Insurance Co Ltd, <br />
                    No.1, New Tank Street, Valluvarkottam High Road, <br />
                    Nungambakkam, <br />
                    Chennai - 600 034 <br />
                    Email : support@starhealth.in
                  </h5> */}
                  <InfoArea
                    style={{ padding: `0px`, margin: `0px` }}
                    className={classes.infoArea}
                    title="Mail In"
                    description={
                      <span>
                        <h5 className={classes.description}>
                          The Manager - Customer Care, <br />
                          Star Health and Allied Insurance Co Ltd, <br />
                          No.1, New Tank Street, Valluvarkottam High Road,{` `}
                          <br />
                          Nungambakkam, <br />
                          Chennai - 600 034 <br />
                          Email : support@starhealth.in
                        </h5>
                      </span>
                    }
                    icon={MailOutline}
                  />
                  <InfoArea
                    style={{ padding: `0px`, margin: `0px` }}
                    className={classes.infoArea}
                    title="Branch Locator"
                    description={
                      <span>
                        To view all our branches click{` `}
                        <Link style={{textDecoration:`underline`}} to="/locate-us">here</Link>
                      </span>
                    }
                    icon={PinDrop}
                  />
                  <InfoArea
                    style={{ padding: `0px`, margin: `0px` }}
                    className={classes.infoArea}
                    title="Call Us 24x7 at"
                    description={
                      <div>
                        <span>
                          <h5 className={classes.title} style={{ margin: 0 }}>
                          Corporate Customers 
                          </h5>
                          <CallComponent contactNumber="044 43664666"/>
                        </span>
                        <span>
                          <h5 className={classes.title} style={{ margin: 0 }}>
                            For Other Users{` `}
                          </h5>
                          (044)- <CallComponent contactNumber="2831 9100" code="044" /> / <CallComponent code="044" contactNumber="2853 2060" /> / <CallComponent code="044" contactNumber="2853 2030" />
                        </span>
                      </div>
                    }
                    icon={Phone}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} className={classes.mlAuto}>
                  <Card className={classes.card1}>
                    <form onSubmit={this.handleSubmit}>
                      <CardHeader
                        contact
                        color="primary"
                        className={classes.textCenter}
                      >
                        <h4 className={classes.cardTitle}>Contact Us</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Name *"
                              id="name"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.name[0]}
                              error={
                                this.state.submitButton &&
                                this.state.name[1] &&
                                this.state.name[0].length === 0
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Mobile *"
                              id="mobile"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true,
                                  // pattern: `[0-9]{10}`
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.mobile[0]}
                              error={
                                (this.state.submitButton &&
                                  this.state.mobile[1] &&
                                  !this.state.mobile[0]) ||
                                (this.state.mobile[0].length != 0 &&
                                  this.state.mobile[0].search(phoneno) !== 0)
                              }
                            />
                            {this.state.mobile[0].length != 0 &&
                              this.state.mobile[0].search(phoneno) !== 0 && (
                              <FormHelperText id="my-helper-text" error>
                                  Mobile Number is Numeric{` `}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <CustomInput
                          labelText="Email Address *"
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={
                            {
                              // required: true,
                              // type: `email`
                            }
                          }
                          onChange={this.handleChange}
                          value={this.state.email[0]}
                          error={
                            (this.state.submitButton &&
                              this.state.email[1] &&
                              !this.state.email[0]) ||
                            (this.state.email[0].length !== 0 &&
                              this.state.email[0].search(emailRegex) !== 0)
                          }
                        />
                        {this.state.email[0].length != 0 &&
                          this.state.email[0].search(emailRegex) !== 0 && (
                          <FormHelperText
                            id="my-helper-text"
                            className={classes.selectFormControl}
                            error
                          >
                              Email Id is invalid{` `}
                          </FormHelperText>
                        )}

                        <CustomInput
                          labelText="Your Message *"
                          id="message"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            multiline: `true`,
                            rows: 5
                            // required: true
                          }}
                          onChange={this.handleChange}
                          value={this.state.message[0]}
                          error={
                            this.state.submitButton &&
                            this.state.message[1] &&
                            !this.state.message[0]
                          }
                        />
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              className={classes.formControl}
                              error={
                                this.state.submitButton &&
                                this.state.query.length === 0
                              }
                              fullWidth
                            >
                              <InputLabel
                                htmlFor="query-type"
                                className={classes.selectLabel}
                              >
                                Query Type *
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={
                                  dataout == ``
                                    ? this.state.query || ``
                                    : `feedback`
                                }
                                onChange={this.handleSimple}
                                style={{ display: `block` }}
                                inputProps={{
                                  name: `query`,
                                  id: `query`
                                }}
                                input={<Input id="name-error" />}
                              >
                                {
                                  menuItems.map((menu,index) => 
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={menu.key}
                                    >
                                      {menu.title}
                                    </MenuItem>
                                  )
                                }
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem md={12} style={{ paddingTop: 27 }}>
                            <Captcha
                              fullWidth
                              nextLine={true}
                              refresh={this.state.refreshCaptcha}
                              updateRefresh={this.updateRefreshCaptcha}
                              submitButton={this.state.submitButton}
                              setCaptchaText={this.setCaptchaText}
                            />
                          </GridItem>
                          <GridItem md={12} style={{ paddingTop: 27 }}>
                            <Button
                              color="primary"
                              style={{ float: `left` }}
                              type={`submit`}
                              round
                              className={classes.buttonfix}
                            >
                              Send Message
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </form>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withStyles(contactsStyle)(ContactUs);

ContactUs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};

export const contactUsQuery = graphql`
  query ContactUs {
    allStrapiImages(filter: { section: { category: { eq: "contact_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
